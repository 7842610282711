<template>
  <div class="col-12 col-lg-10 g c">
    <div class="card card-body">
        <h4>
            احصائيات حضور وغياب وتأخر الطلاب في الفصول اليوم.
        </h4>
        <br>
        <div class="col-12 table-responsive">
            <button class="btn btn-warning btn-sm" style="border-radius: 0px" @click="print()">
                <i class="fa fa-print"></i>
                طباعة الجدول
            </button>
            <table class="table table-hover table-bordered table-sm" id="print">
                <thead>
                    <th>
                        الصف
                    </th>
                    <th>
                        الفصل
                    </th>
                    <th>
                        اجمالي الطلاب
                    </th>
                    <th>
                        الحضور
                    </th>
                    <th>
                        التأخر
                    </th>
                    <th>
                        الغياب بعذر
                    </th>
                    <th>
                        الغياب بدون عذر
                    </th>
                    <th>
                        الانصرافات
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(a,b) in classrooms" :key="b">
                        <td>
                            {{ a.classname }}
                        </td>
                        <td>
                            {{ a.classroom }}
                        </td>
                        <td>
                            {{ a.all }}
                        </td>
                        <td>
                            {{ a[1] + a[2] }}
                        </td>
                        <td>
                            {{ a[2] }}
                        </td>
                        <td>
                            {{ a[5] }}
                        </td>
                        <td>
                            {{ a[3] }}
                        </td>
                        <td>
                            {{ a[4] }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            classrooms: {},
            user: JSON.parse(localStorage.getItem('user')),
        }
    },
    created(){
        var g = this;
        if(!checkPer("students|reports|classrooms")){
            this.$router.push('/NotPermitted')
            return false;
        }
        $.post(api + '/user/students/reports/daily', {
            jwt: this.user.jwt,
            date: new Date().toISOString().split('T')[0],
            classname: null,
            status: null,
            q: null
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                var classrooms = {};
                r.response.forEach(function(a){
                    if(!classrooms[a.classname + a.classroom]){
                        classrooms[a.classname + a.classroom] = {
                            classname: a.classname,
                            classroom: a.classroom,
                            all: 0,
                            0: 0,
                            1: 0,
                            2: 0,
                            3: 0,
                            4: 0,
                            5: 0,
                            6: 0
                        }
                    }
                    classrooms[a.classname + a.classroom].all = classrooms[a.classname + a.classroom].all + 1;
                    classrooms[a.classname + a.classroom][a.status] = classrooms[a.classname + a.classroom][a.status] + 1;
                })
                g.classrooms = classrooms;
            }
        }).fail(function(){
        })
    },
    methods: {
        print(){
            var g  = this;
            var divToPrint=document.getElementById("print");
            var newWin= window.open("");
            newWin.document.write(`
            <style>
            html, body{
                direction: rtl
            }
            .hidemonprint{
                display:none;   
            }
            table, td, th {
            border: 1px solid;
            }

            table {
            width: 100%;
            border-collapse: collapse;
            }
            table, td, th {
                        border: 1px solid;
                        }
                        tr{
                            text-align: center
                        }
                        table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    th{
                        background: #eee
                    }
                    .hidemex{
                        display:none
                    }
                        
            </style>
            <body>
                <div class='row' style='width: 100%; padding: 0px; position: relative;'>
                <div class='col-4 text-center' style='width: 30%; text-align: center; display: inline-block; position: relative; top: -10px'>
                <h5>
                    المملكة العربية السعودية
                    <br><br>وزارة التعليم
                </h5>
                </div>
                <div class='col-4 text-center' style='width: 30%; text-align: center; display: inline-block'>
                    <img style='width: 100px; position: relative; top: 20px' src='https://static.arrajol.com/users/user223141/%D8%B4%D8%B9%D8%A7%D8%B1-%D9%88%D8%B2%D8%A7%D8%B1%D8%A9-%D8%A7%D9%84%D8%AA%D8%B9%D9%84%D9%8A%D9%85-%D8%A7%D9%84%D8%AC%D8%AF%D9%8A%D8%AF-%D8%B4%D9%81%D8%A7%D9%81.png'>
                    <center><h5 class='text-danger'>
            <strong>احصائيات حضور وغياب وتأخر الطلاب في الفصول  </strong>
                    </h5></center>
                </div>
                <div class='col-4 text-center' style='width: 30%; text-align: center; display: inline-block; position: relative; top: -20px'>
                <h5>
                    ${g.user.name}<br>
                    بتاريخ: ${new Date().toISOString().split('T')[0]}
                </h5>
                </div>
                ${divToPrint.outerHTML}
            </body>
            `);
            newWin.document.close()
            setTimeout(() => {
                newWin.print();
            }, 1000);
        },
    }
}
</script>

<style>

</style>